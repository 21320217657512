import { createRouter, createWebHistory } from "vue-router";
import pathLoader from "../plugins/loader";
import store from "@/store";

const routes = [
  /*{
    path: "/login",
    name: "Login",
    component: pathLoader("login", "index"),
  },*/
  {
    path: "/reset",
    name: "Reset",
    component: pathLoader("reset", "index"),
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: pathLoader("reset-password", "index"),
  },
  {
    path: "/setup-password",
    name: "Setup",
    component: pathLoader("setup", "index"),
  },
  {
    path: "/",
    name: "home",
    component: pathLoader(null, "HomeView"),
    redirect: "/dashboards/:token/:firebaseToken",
    children: [
      {
        path: "/dashboards/:token/:firebaseToken",
        name: "Dashboards",
        component: pathLoader("dashboards", "index"),
        meta: {
          requireAuth: false,
        },
      },
      {
        path: "/dashboard",
        name: "Dashboard",
        component: pathLoader("dashboard", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/sinister",
        name: "Sinister",
        component: pathLoader("sinister", "index"),
        meta: {
          requireAuth: true,
        },
      },
      {
        path: "/logout",
        name: "Logout",
        component: pathLoader("logout", "index"),
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth)) {
    // console.log(store.getters.loginToken)
    if (store.getters.loginToken.length > 1) {
      next();
      return;
    }
    next("https://pv.asabenin.org/");
  } else {
    next();
  }
});

export default router;
