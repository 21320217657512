export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur le portail digital de transmission de PV d'accidents de l’ASA Bénin"])},
  "enterYourId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer vos identifiants pour vous connecter"])},
  "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant (Email)"])},
  "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "loginButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
  "forgetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color: #C6C6CA;'>Vous avez oublié votre mot de passe ?</span> <span style='color: #4945FF; cursor: pointer;'>Réinitialiser</span>"])},
  "loginError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email ou mot de passe incorrects"])},
  "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MENU"])},
  "dashboardMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tableau de bord"])},
  "sinisterMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PV Accidents"])},
  "logoutMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
  "madeByMenu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color: #B8BFCE;'>Propulsé par </span><span><a href='https://www.41devs.com/' target='_blank' style='color: #FFFFFF;'>41devs</a></span>"])},
  "activityFigures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chiffres d’activité"])},
  "filedMinutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PV déposés"])},
  "accidentsVictims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Victimes d’accidents"])},
  "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
  "filedMinutesButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposer un procès verbal"])},
  "searchMinutes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher un PV"])},
  "sinisterComingDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de survenance du sinistre"])},
  "minutesNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº du PV"])},
  "sinisterNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº du sinistre"])},
  "matriculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immatriculation"])},
  "startSearchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lancer la recherche"])},
  "accidentPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de l’accident"])},
  "victims": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Victimes"])},
  "vehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véhicules"])},
  "insurers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assureurs"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
  "fileClaimReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposer un Procès Verbal d'accident"])},
  "victimsNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de victimes"])},
  "concernedVehicles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véhicules impliqués"])},
  "addVehicleButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un véhicule"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations complémentaires"])},
  "joinReportHere": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joindre le PV ici"])},
  "clickAddPiece": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquer pour ajouter la pièce"])},
  "saveReportButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer le PV"])},
  "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
  "matriculationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro d’immatriculation"])},
  "insuredName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nom de l’assuré"])},
  "insurer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assureur"])},
  "saveButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
  "selectCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez la compagnie"])},
  "searchCompany": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une compagnie"])},
  "saved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistré"])},
  "successReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôt de procès verbal effectué avec succès"])},
  "backToReport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour aux PV"])},
  "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procès Verbal"])},
  "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
  "leaveBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déposé par"])},
  "sinisterPlace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu du sinistre"])},
  "joinedDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document joint"])},
  "downloadDocumentButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le document"])},
  "existingUsernameError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant existant"])},
  "successCreateAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte créé avec succès. Un lien vous a été envoyé par mail pour définir votre mot de passe."])},
  "fillPasswordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs"])},
  "passwordError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe et confirmation non conformes"])},
  "characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 caractères"])},
  "numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contient un nombre"])},
  "uppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contient une majuscule"])},
  "specialCharacters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contient un caractère spécial"])},
  "noUserAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur ajouté"])},
  "leaveApp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir vous déconnecter ?"])},
  "passwordReset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation de mot de passe"])},
  "enterUsername": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer votre identifiant pour finaliser la réinitialisation de mot de passe"])},
  "enterPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrer un nouveau mot de passe pour finaliser la réinitialisation"])},
  "noInsuranceAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune compagnie d'assurance ajoutée"])},
  "noPoliceAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun commissariat de police ajouté"])},
  "noSinisterAdded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune déclaration de sinistre ajoutée"])},
  "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la compagnie"])},
  "accountFinalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finalisation du compte"])},
  "createPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un mot de passe pour finaliser la configuration de votre compte"])},
  "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
  "continuousButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
  "vehicleType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de véhicule"])},
  "car": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voiture"])},
  "moto": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moto"])}
}